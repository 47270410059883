function isMob() {
    return window.innerWidth < 768;
}

/**
 * 
 * @param {string} rawStr 
 * @returns 
 */
function encodeHtml(rawStr) {
    return rawStr.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';')
}

/**
 * 
 * @param {string} string 
 * @returns 
 */
function capitalize(string)
{
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 
 * @param {string} string 
 * @returns 
 */
function decapitalize(string)
{
    if (!string) return string;
    return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * 
 * @param {string} str 
 * @returns 
 */
function toCamelCase(str) {
    return str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
}

/**
 * 
 * @param {string} str 
 * @returns 
 */
function toSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

/**
 * 
 * @param {any[]} array 
 * @param {string | (e: any) => string} key 
 * @returns {Object.<string, any[]>}
 */
function groupBy(array, key) {
    let useFunction = typeof key == 'function';

    return array.reduce((acc, currentValue) => {
        let groupKey = useFunction ? key(currentValue).toString() : currentValue[key].toString();
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
    }, {});
}

function sort(array, desc, mapper){
    if (!array.length) return array;
    if (!mapper) mapper = e => e;
    let copy = [...array];
    copy.sort((a, b) => {
        if (mapper(a) > mapper(b)) return 1;
        if (mapper(a) < mapper(b)) return -1;
        return 0;
    });

    if (desc) copy.reverse();
    return copy;
}

/**
 * convert string to bool
 * @param {*} value 
 * @param {*} isAttr treat as html attribute, so empty string is true
 * @returns 
 */
function toBool(value, isAttr){
    if (isAttr && value == '') return true;
    if (value == 'false' || value == '0') return false;
    return !!value;
}

function renderSimpleTemplate(template, data, requireFields, encodeUrl) {
    if (!template || !data) return '';

    let result = template;
    let templateRegex = /{{([a-zA-Z0-9_]+)}}/g;
    for (let field of template.matchAll(templateRegex)) {
        let value = data[field[1]] || '';
        if (requireFields && !value) {
            return null;
        }

        if (encodeUrl) value = encodeURIComponent(value);
        result = result.replace(field[0], value);
    }

    return result;
}

/**
 * 
 * @param {any[]} array 
 * @returns 
 */
function distinct(array) {
    if (!array) return [];
    function distinctFilter(value, index, self) { 
        return self.indexOf(value) === index;
    }

    return array.filter(distinctFilter);
}

/**
 * 
 * @param {string} date 
 * @returns {Date}
 */
function parseDate(date) {
    if (!date.includes(' ') && !date.includes('T')) date = date + ' 00:00';
    if (date.includes('+')) return new Date(date);
    return new Date(`${date}+09:00`);
}

function isDateInThisWeek(date, offset) {
    if (!offset) offset = 0;
    let today = new Date();
    const todayObj = new Date(today.setDate(today.getDate() + offset * 7));
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
}


/**
 * позволяет получить из сложного объекта вложенное поле по строке типа 'products[0].info.name'
 * @param {object} object 
 * @param {string} path 
 * @returns 
 */
function getValueByPath(object, path) {
    let result = object;
    let sections = path.split('.');
    for (let section of sections) {
        if (!result) return result;
        let arrayRegex = /^([a-zA-Z0-9_]+)\[(\d+)\]$/;
        let match = section.match(arrayRegex);
        let index = match ? match[2] : null;
        if (index) section = match[1];

        if (index) {
            result = result[section][index] || null;
        } else {
            result = result[section] || null;
        }
    }

    return result;
}

/**
 устанавливает значение вложенного поля по строке типа 'products.info.name'
 * @param {*} object 
 * @param {*} path 
 * @param {*} value 
 */
function setValueByPath(object, path, value) {
    let schema = object;  // a moving reference to internal objects within obj
    let pList = path.split('.');
    let len = pList.length;
    for (let i = 0; i < len-1; i++) {
        let elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
}

function isIos(){
    if ((/iPad|iPhone|iPod/.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))	{
        return true;
    }

    return false;
}

function swapAltHtml(element, attributeName) {
    if (!attributeName) attributeName = 'data-althtml';
    let oldhtml = element.innerHTML;
    let newhtml = element.getAttribute(attributeName);
    element.setAttribute(attributeName, oldhtml);
    element.innerHTML = newhtml;
}

export { 
    isMob, encodeHtml, capitalize, decapitalize, toCamelCase, groupBy, sort, 
    toBool, renderSimpleTemplate, distinct, toSnakeCase, parseDate, getValueByPath, setValueByPath, isDateInThisWeek,
    isIos, swapAltHtml
};